import { createContext, FC, useCallback, useEffect, useState } from 'react';

import {
  InvokedAlarmDto, InvokedAlarmRequestDto, InvokedAlarmService,
  OdometerDto,
  OdometerService
} from "@/_generatedApi";
import CDataGrid, { ColDef } from '@/components/ui/common/data-grid/CDataGrid';
import { Option } from '@/components/ui/fields/types';
import ElectricityAlarmsHistoryFilters, {
  ElectricityAlarmHistoryFilter,
} from '@/components/ui/filters/ElectricityAlarmsHistoryFilters';
import { ITEMS_PER_PAGE, MAX_TAKE_ITEMS } from '@/constants/pagination';
import { useShowToast } from '@/hooks/use-show-toast';
import { updateFilterData, updateObjectInArray } from "@/utils/immutable";
import { format } from "date-fns";
import CModal from "@/components/ui/common/modal/CModal";
import InvokedAlarmForm, { InvokedAlarmFormOutputValues } from "@/components/electricity-alarms/InvokedAlarmForm";
import { useTranslation } from "react-i18next";
import { nullFieldsToUndefined } from "@/utils/validation";

export const ElectricityAlarmsHistoryContext = createContext<Option[] | null>(
  null
);

const ElectricityAlarmsHistoryPage: FC = () => {
  const { t } = useTranslation();
  const [alarmsHistory, setAlarmsHistory] = useState<InvokedAlarmDto[]>(
    []
  );
  const [odometers, setOdometers] = useState<Option[]>([]);
  const [countOfAlarmsHistory, setCountOfAlarmsHistory] = useState(0);
  const { showGenericErrorToast, showToast } = useShowToast();

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(ITEMS_PER_PAGE);
  const [filter, setFilter] = useState<ElectricityAlarmHistoryFilter>({});

  const [showModal, setShowModal] = useState(false);
  const [chosenAlarm, setChosenAlarm] = useState<InvokedAlarmDto|null>(null);

  const alarmsHistoryColumnsDef: ColDef<InvokedAlarmDto>[] = [
    { key: 'odometer', nested: true, isDate: false, sortable: true },
    { key: 'reason', nested: false, isDate: false, sortable: true },
    { key: 'eventDate', nested: false, isDate: true, sortable: true, valueGetter: ({ row }) => format(new Date(row['eventDate']), 'dd.MM.yyyy HH:mm') },
    { key: 'note', nested: false, isDate: false, sortable: false },
  ];

  const fetchAlarmsHistory = useCallback(async () => {
    try {
      const alarmsHistory = await InvokedAlarmService.getInvokedAlarmHistory({
        // Both interfaces are generated automatically based on the api definition, unfortunately incompatible
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ...(filter as any),
      });

      setAlarmsHistory(alarmsHistory.data || []);
      setCountOfAlarmsHistory(alarmsHistory.data?.length || 0);
    } catch (e) {
      showGenericErrorToast();
    }
  }, [filter]);

  const fetchOdometers = useCallback(async () => {
    try {
      const odometers = await OdometerService.getOdometerList({
        sortBy: 'name',
        descending: false,
        take: MAX_TAKE_ITEMS,
        skip: 0,
      });

      const mappedOdometersToSelect = odometers.data?.map((item): Option => {
        const odometer = item as OdometerDto;
        return {
          value: odometer?.id || 0,
          label: odometer?.name || '',
        };
      });

      if (mappedOdometersToSelect?.length) {
        setOdometers(mappedOdometersToSelect);
      }
    } catch (e) {
      showGenericErrorToast();
    }
  }, [odometers]);

  useEffect(() => {
    fetchOdometers();
  }, []);

  useEffect(() => {
    fetchAlarmsHistory();
  }, [filter, rowsPerPage, currentPage]);

  const handleEditAlarm = (alarm: InvokedAlarmDto) => {
    if (!alarm) return;
    const foundAlarm = alarmsHistory.find((item) => item.id === parseInt(alarm.id as any as string));
    if (!foundAlarm) return;
    setChosenAlarm(foundAlarm);
    setShowModal(true);
  };

  const handleCancel = () => {
    setChosenAlarm(null);
    setShowModal(false);
  };

  const handleSubmit = async (
    values: InvokedAlarmFormOutputValues,
    id?: number | null
  ) => {
    const valuesWithoutNullFields = nullFieldsToUndefined(values);
    try {
      if (id) {
        const data = (
          await InvokedAlarmService.putInvokedAlarmUpdate({
            id,
            requestBody: valuesWithoutNullFields as InvokedAlarmRequestDto,
          })
        ).data;

        if (data) {
          setAlarmsHistory(
            (alarms) => updateObjectInArray(alarms, data) as InvokedAlarmDto[]
          );
        }

        setChosenAlarm(null);

        showToast('success', t('notifications.itemWasSuccessfullyEdited'));
      }

      setShowModal(false);
    } catch (e) {
      showGenericErrorToast();
    }
  };

  const handleChangePage = (page: number) => {
    setCurrentPage(page);
  };

  const updateFilter = (key: string, value: string) => {
    setFilter((filter) => updateFilterData(filter, { key, value }));
  };

  return (
    <ElectricityAlarmsHistoryContext.Provider value={odometers}>
      <CDataGrid
        colDef={alarmsHistoryColumnsDef}
        translationKey="electricityPage.alarmHistoryPage"
        data={alarmsHistory}
        onEdit={(values) => handleEditAlarm(values as InvokedAlarmDto)}
        filters={
          <ElectricityAlarmsHistoryFilters
            updateFilter={updateFilter}
            filter={filter}
          />
        }
        changeRowsPerPage={(rowsPerPage: number) => setRowsPerPage(rowsPerPage)}
        onSort={(sortModel) => {
          setFilter((filter) => ({
            ...filter,
            sortBy: sortModel.sortBy,
            descending: sortModel.descending,
          }));
        }}
        pagination={{
          onPageChange: handleChangePage,
          totalCount: countOfAlarmsHistory,
          currentPage,
          pageSize: rowsPerPage,
        }}
      />
      <CModal
        open={showModal}
        title={t('electricityPage.alarmHistoryPage.title')}
        type="edit"
        onClose={handleCancel}
      >
        <InvokedAlarmForm
          alarm={chosenAlarm}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      </CModal>
    </ElectricityAlarmsHistoryContext.Provider>
  );
};
export default ElectricityAlarmsHistoryPage;
