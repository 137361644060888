import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import * as yup from 'yup';

import { InvokedAlarmDto } from "@/_generatedApi";
import Button from '@/components/ui/common/button/CButton';
import Input from '@/components/ui/fields/Input';
import { Option } from '@/components/ui/fields/types';
import Field from '@/components/ui/form/Field';
import Form from '@/components/ui/form/Form';
import { useShowToast } from '@/hooks/use-show-toast';

const validationSchema = yup.object({
  note: yup.string().nullable(),
});

export type InvokedAlarmFormProps = {
  alarm: InvokedAlarmDto | null;
  onSubmit: (
    values: InvokedAlarmFormOutputValues,
    id?: number | null
  ) => void;
  onCancel: () => void;
};

export type InvokedAlarmFormInputValues = {
  note: string | null;
};

export type InvokedAlarmFormOutputValues = yup.InferType<
  typeof validationSchema
>;

const ElectricityAlarmForm: FC<InvokedAlarmFormProps> = ({
  alarm,
  onSubmit,
  onCancel,
}) => {
  const { t } = useTranslation();
  const dialogType = 'update';

  const { showGenericErrorToast } = useShowToast();

  const [users, setUsers] = useState<Option[]>([]);

  const initialValues: InvokedAlarmFormInputValues = {
    note: alarm?.note || null,
  };

  const handleSubmit = async (values: InvokedAlarmFormInputValues) => {
    onSubmit(
      values as InvokedAlarmFormOutputValues,
      alarm ? alarm.id : undefined
    );
  };

  return (
    <Box
      sx={{
        maxWidth: '400px',
        width: '100%',
        display: 'flex',
        margin: 'auto',
        flexDirection: 'column',
        paddingY: '24px',
      }}
    >
      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, resetForm, values }) => (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Field<string>
              name="note"
              render={(props) => (
                <Input
                  type="text"
                  label={t('electricityPage.alarmHistoryPage.note')}
                  {...props}
                  required
                  multiline
                  data-cy-field="value"
                />
              )}
            />

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Button
                type="button"
                size="large"
                data-cy-button="cancel"
                onClick={() => {
                  resetForm();
                  onCancel();
                }}
                onMouseDown={(e) => e.preventDefault()}
              />
              <Button
                disableElevation
                type="submit"
                size="large"
                disabled={isSubmitting}
                data-cy-button="submit"
                variant={dialogType}
                onMouseDown={(e) => e.preventDefault()}
              />
            </Box>
          </Box>
        )}
      </Form>
    </Box>
  );
};

export default ElectricityAlarmForm;
