/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InvokedAlarmDto } from '../models/InvokedAlarmDto';
import type { InvokedAlarmRequestDto } from '../models/InvokedAlarmRequestDto';
import type { ResponseOne } from '../models/ResponseOne';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class InvokedAlarmService {
  /**
   * @returns any Successful operation
   * @throws ApiError
   */
  public static getInvokedAlarmHistory({
    odometerId,
    sortBy,
    descending,
  }: {
    odometerId?: number;
    sortBy?: 'odometer' | 'eventDate' | 'reason';
    descending?: boolean;
  }): CancelablePromise<{
    metadata?: ResponseOne;
    data?: Array<InvokedAlarmDto>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/invoked-alarm/history',
      query: {
        odometerId: odometerId,
        sortBy: sortBy,
        descending: descending,
      },
    });
  }

  /**
   * @returns any Successful operation
   * @throws ApiError
   */
  public static putInvokedAlarmUpdate({
    id,
    requestBody,
  }: {
    id: number;
    /**
     * Update alarm
     */
    requestBody: InvokedAlarmRequestDto;
  }): CancelablePromise<{
    metadata?: ResponseOne;
    data?: InvokedAlarmDto;
  }> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/invoked-alarm/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        404: `Alarm not found`,
      },
    });
  }
}
