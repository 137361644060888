/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AlarmDto } from '../models/AlarmDto';
import type { AlarmRequestDto } from '../models/AlarmRequestDto';
import type { ResponseMany } from '../models/ResponseMany';
import type { ResponseOne } from '../models/ResponseOne';
import type { SuccessfulResponse } from '../models/SuccessfulResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AlarmService {
  /**
   * @returns any Alarms list.
   * @throws ApiError
   */
  public static getAlarmList({
    take,
    skip,
    deleted,
    sortBy,
    descending,
    odometerId,
  }: {
    take: number;
    skip: number;
    deleted?: boolean;
    sortBy?: 'id' | 'odometer';
    descending?: boolean;
    odometerId?: string;
  }): CancelablePromise<{
    metadata?: ResponseMany;
    data?: Array<AlarmDto>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/alarm/',
      query: {
        deleted: deleted,
        take: take,
        skip: skip,
        sortBy: sortBy,
        descending: descending,
        odometerId: odometerId,
      },
    });
  }

  /**
   * @returns any Successful operation
   * @throws ApiError
   */
  public static postAlarmCreate({
    requestBody,
  }: {
    /**
     * Create new alarm
     */
    requestBody: AlarmRequestDto;
  }): CancelablePromise<{
    metadata?: ResponseOne;
    data?: AlarmDto;
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/alarm/',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any Successful operation
   * @throws ApiError
   */
  public static getAlarmFind({ id }: { id: number }): CancelablePromise<{
    metadata?: ResponseOne;
    data?: AlarmDto;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/alarm/{id}',
      path: {
        id: id,
      },
      errors: {
        404: `User not found`,
      },
    });
  }

  /**
   * @returns any Successful operation
   * @throws ApiError
   */
  public static putAlarmUpdate({
    id,
    requestBody,
  }: {
    id: number;
    /**
     * Update alarm
     */
    requestBody: AlarmRequestDto;
  }): CancelablePromise<{
    metadata?: ResponseOne;
    data?: AlarmDto;
  }> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/alarm/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        404: `Alarm not found`,
      },
    });
  }

  /**
   * @returns SuccessfulResponse Successful operation
   * @throws ApiError
   */
  public static deleteAlarmDelete({
    id,
  }: {
    id: number;
  }): CancelablePromise<SuccessfulResponse> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/alarm/{id}',
      path: {
        id: id,
      },
      errors: {
        404: `Alarm not found`,
      },
    });
  }
}
