export default {
  translation: {
    common: {
      actions: 'Akce',
      detail: 'Detail',
      open: 'Otevřít',
      close: 'Zavřít',
      back: 'Zpět',
      delete: 'Smazat',
      confirm: 'Potvrdit',
      cancel: 'Zrušit',
      add: 'Přidat',
      edit: 'Upravit',
      show: 'Zobrazit',
      hide: 'Skrýt',
      clear: 'Smazat',
      select: 'Vyberte',
      continue: 'Pokračovat',
      clearSelect: 'smazat výběr',
      save: 'Uložit',
      areYouSureToDeleteItem: 'Opravdu chcete odstranit položku: {{item}}?',
      areYouSureToDeleteItemCommon: 'Opravdu chcete odstranit tuto položku?',
      empty: 'Prázdné',
      rowsPerPage: 'Počet řádků na stránku',
      noDataFound: 'Nebyla nalezena žádná data',
      submit: 'Odeslat',
      minutesShort: '{{value}} min',
    },
    localization: {
      cs: 'Česky',
      en: 'English',
      language: 'Jazyk',
      changeLanguageAction: 'Změnit jazyk',
    },
    navigation: {
      energy: 'Energie',
      'energy-dashboard': 'Energie',
      backToHomepage: 'Zpět na domovskou stránku',
      environment: 'Životní prostředí',
      electricity: 'Elektřina',
      water: 'Voda',
      gas: 'Plyn',
      online: 'Online',
      readings: 'Elektroměry',
      'water-readings': 'Vodoměry',
      'gas-readings': 'Plynoměry',
      alarms: 'Alarmy',
      dashboard: 'Přehled',
      settings: 'Nastavení',
      'branch-offices': 'Provozovny',
      'historical-odometers': 'Historie měřidel',
      'alarms-history': 'Historie alarmů',
      workshops: 'Haly',
      centers: 'Střediska',
      groups: 'Skupiny',
      odometers: 'Měřidla',
      users: 'Uživatelé',
      filters: 'Filtry',
      sanitizers: 'Očištění dat',
      'processor-configs': 'Zpracování dat',
      'workshop-overview': 'Haly',
      sankey: 'Šála',
      'power-factor': 'Účiník',
      'power-factors': 'Účiník',
    },
    error: {
      unauthorized: 'Heslo nebo Uživatelské jméno je špatně',
      internalServerError: 'Něco se porouchalo. Kontaktuje podporu.',
      generic: 'Něco se pokazilo',
      pageNotFound: 'Stránka nebyla nalezena nalezena',
    },
    notifications: {
      itemWasSuccessfullyCreated: 'Položka byla úspěšně vytvořena.',
      itemWasSuccessfullyEdited: 'Položka byla úspěšně upravena.',
      itemWasSuccessfullyDeleted: 'Položka byla úspěšně odstraněna.',
      successChangePassword: 'Heslo bylo úspěšně změněno.',
      requestForResetPasswordWasSent:
        'Požadavek na změnu zapomenutého hesla byl odeslán.',
    },
    validation: {
      nameMinChars: 'Název musí obsahovat minimálně 4 znaky',
      minChars: 'Počet znaků musí být minimálně {{count}}',
      maxChars: 'Počet znaků může být maximálně {{count}}',
      required: 'Povinné pole',
      phone: 'Nevalidní telefonní číslo (příklad: +420999999999).',
      zeroOrMore: 'Musí být 0 a více',
      hundredOrLess: 'Musí být 100 a méně',
      invalidDate: 'Nevalidní datum',
      numberList: 'Nevalidní seznam čísel (příklad: 1,3,5-8,11)',
    },
    loginPage: {
      login: 'Přihlásit se',
      logout: 'Odhlásit se',
      successLoginMessage: 'Uživatel se úspěšně přihlásil',
      username: 'Uživatelské jméno',
      password: 'Heslo',
      forgotPassword: 'Zapomenuté heslo',
      newPassword: 'Nové heslo',
      'toggle password visibility': 'přepnout viditelnost hesla',
      oldPassword: 'Původní heslo',
      repeatNewPassword: 'Zopakuj heslo',
      sendLink: 'Poslat odkaz',
      emailAddress: 'Emailová adresa',
      forgotPasswordInfo:
        'Zadejte e-mailovou adresu na kterou má být zaslán odkaz pro obnovu hesla.',
      validation: {
        passwordsDontMatch: 'Hesla se neshodují',
        'One uppercase character': 'Alespoň jeden velký znak',
        'One lowercase character': 'Alespoň jeden malý znak',
        'One number': 'Alespoň jedno číslo',
        'One special character': 'Alespoň jeden speciální znak',
        'Must be at least 8 characters in length': 'Musí mít alespoň 8 znaků',
      },
    },
    forgotPasswordPage: {
      backToLogin: 'Zpět na přihlášení',
    },
    changePasswordPage: {
      backToLogin: 'Zpět na přihlašovací stránku',
    },
    underConstructionPage: {
      title: 'Stránka je ve výstavbě',
    },
    profilePage: {
      title: 'Nastavení účtu',
      logout: 'Odhlásti se',
      username: 'Uživatelské jméno',
      tooltip: 'Nastavení účtu',
      firstName: 'Jméno',
      lastName: 'Příjmení',
      email: 'E-mail',
      contact: 'Kontakt',
      changePassword: 'Změnit heslo',
    },
    odometersSettingsPage: {
      title: 'Měřidla',
      odometerInfo: 'Informace o měřidle',
      assigned: 'Přiřazení',
      mapToOdometer: 'Mapování vstupů',
      name: 'Název měřidla',
      medium: 'Médium',
      type: 'Typ měřidla',
      installationDate: 'Datum připojení',
      branchOffice: 'Provozovna',
      branchOfficeName: 'Název provozovny',
      workshop: 'Hala',
      workshopName: 'Název haly',
      center: 'Středisko',
      centerName: 'Název střediska',
      group: 'Skupina',
      groupName: 'Název skupiny',
      comment: 'Poznámka',
      optimalConsumption: 'Optimální spotřeba',
      maxHourlyValue: 'Horní mez',
      coefficient: 'Koeficinet',
      idByUser: 'ID zadané uživatelem',
      odometerValue: 'Výchozí hodnota čítače',
      odometerValueDate: 'Datum výchozí hodnoty čítače',
      inputNumber: 'Číslo vstupu',
      technicalName: 'Technický název měřidla',
      externalId: 'MAC adresa',
      remainingFilters: 'Zbývá ještě zvolit následující filtry',
    },
    usersSettingsPage: {
      title: 'Uživatelé',
      firstName: 'Jméno',
      lastName: 'Příjmení',
      email: 'E-mail',
      phone: 'Telefonní číslo',
      role: 'Role',
      username: 'Uživatelské jméno',
      registrationPassword: 'Registrační heslo',
      active: 'Aktivní',
    },
    workshopsSettingsPage: {
      title: 'Haly',
      name: 'Název',
      location: 'Lokace',
      branchOffice: 'Provozovna',
      comment: 'Poznámka',
    },
    centersSettingsPage: {
      title: 'Střediska',
      name: 'Název',
      location: 'Lokace',
      branchOffice: 'Provozovna',
      workshop: 'Hala',
      comment: 'Poznámka',
    },
    branchOfficesSettingsPage: {
      title: 'Provozovny',
      name: 'Název',
      city: 'Město',
      comment: 'Poznámka',
      superiorBranchOffice: 'Nadřazená provozovna',
    },
    filterSettingsPage: {
      pageTitle: 'Filtry',
      title: 'Název',
      externalId: 'MAC adresa',
    },
    sanitizersSettingsPage: {
      title: 'Očištění dat',
      externalId: 'MAC adresa',
      value: 'Hodnota',
    },
    processorConfigsSettingsPage: {
      title: 'Nastavení zpracování dat',
      externalId: 'MAC adresa',
      type: 'Druh',
      config: 'Nastavení',
      value: 'Hodnota',
      maximumValueConfig: 'Maximální hodnota čítače',
    },
    electricityPage: {
      alarmPage: {
        title: 'Alarmy / Reporty',
        name: 'Název',
        odometer: 'Název měřidla',
        type: 'Typ',
        value: 'Hodnota',
        intervalValue: 'Interval',
        periodValue: 'Úsek',
        fromDate: 'Čas od',
        toDate: 'Čas do',
        users: 'Notifikovaní uživatelé',
        externalNotification: 'Upozornění mimo firmu',
        externalEmail: 'Přidat externím e-mail',
        externalPhone: 'Přidat externí telefon',
        sendByPhone: 'Telefonem',
        sendByEmail: 'E-mailem',
        notify: 'Upozonění:',
        textTemplate: 'Šablona'
      },
      alarmHistoryPage: {
        title: 'Historie alarmů',
        odometer: 'Název měřidla',
        reason: 'Příčina',
        eventDate: 'Datum překročení hranice',
        note: 'Poznámka',
      },
      dashboardPage: {
        title: 'Přehled',
        consumptionStartDate: 'Od',
        consumptionEndDate: 'Do',
        units: 'Časová jednotka',
        consumptionUnit: 'Časová jednotka',
        type: 'Typ měřidla',
        timeInterval: 'Časový interval',
        odometerName: 'Název měřidla',
        medium: 'Médium',
        odometerType: 'Typ měřidla',
        branchOffice: 'Pobočka',
        workshop: 'Hala',
        center: 'Středisko',
        group: 'Skupina',
        odometer: 'Měřidlo',
        consumptionUnitEnum: {
          '1 minute': '1 minuta',
          '15 minutes': '15 minut',
          '1 hour': '1 hodina',
          '1 day': '1 den',
          '1 week': '1 týden',
          '1 month': '1 měsíc',
          '3 months': '3 měsíce',
          '6 months': '6 měsíců',
          '1 year': '1 rok',
        },
        timeIntervalEnum: {
          '1 day': '1 den',
          '1 week': '1 týden',
          '1 month': '1 měsíc',
          '3 months': '3 měsíce',
          '6 months': '6 měsíců',
          '1 year': '1 rok',
          selectInterval: 'Vlastní interval',
        },
        electricityConsumptionForInterval:
          'Spotřeby elektriky za vybrané období dle filtru',
        electricityIncrementalConsumptionGraph:
          'Přírůstkový graf spotřeby elektriky za vybrané období',
        electricityTrendOfHighestElectricityConsumptionGraph:
          'Trend největších odběrů elektřiny',
        electricityQuarterHourPeakGrowthGraph: 'Vývoj 1/4 hodinových maxim',
        electricity: 'Elekřina',
        energyConsumption: 'Spotřeba Energie {{unit}}',
        showSum: 'Součty',
        showMinuteData: '1/4 maxima',
        showTrendData: '1/4 průběh',
        day: 'Den'
      },
      readingsPage: {
        title: 'Odečty',
      },
      electricityOdometersHistoryPage: {
        title: 'Historická měřidla',
        name: 'Interní název',
        medium: 'Médium',
        type: 'Typ měřidla',
        installationDate: 'Datum připojení',
        deletedAt: 'Datum odpojení',
        branchOffice: 'Provozovna',
        workshop: 'Hala',
        center: 'Středisko',
        note: 'Poznámka',
      },
    },
    electricityReadingsPage: {
      electricityTitle: 'Elektřina',
      odometerName: 'Název měřidla',
      gauge: {
        counterTitle: 'Celková spotřeba',
        counterUnit: 'kWh',
        graphUnit: 'kWh/hod',
        detail: {
          period: 'Období',
          consumptionPer: 'Spotřeba za',
          perDay: 'den',
          perWeek: 'týden',
          perMonth: 'měsíc',
          perYear: 'rok',
          month: 'Měsíc',
          value: 'Hodnota',
          consumption: 'Spotřeba',
          consumptionPerYear: 'Spotřeba za rok: {{value}} {{unit}}',
          from: 'Od',
          to: 'Do',
          maxValueDate: 'Nejvyšší 1/4',
        },
      },

      filters: {
        medium: 'Médium',
        type: 'Typ měřidla',
        branchOffice: 'Provozovna',
        workshop: 'Hala',
        center: 'Středisko',
      },
    },
    dashboardPage: {
      consumption: 'Spotřeba',
      fromStartOfActualMonth: 'od začátku aktuálního měsíce',
      electricity: 'Elektřina',
      water: 'Voda',
      gas: 'Plyn',
      wastes: 'Odpady / kaly',
      alarms: 'Alarmy',
      forLastSelectedPeriod: 'za poslední období',
      moreAlarms: 'Více alarmů',
      noAlarms: 'Neevidujeme žádné alarmy.',
      electricityGraphDescription:
        'Trend spotřeby za předchozí období po měsících - sloupcový graf',
      lastYearValue: 'Hodnota předchozího roku',
      totalValue: 'Celková hodnota',
      valueBelowAverage: 'Hodnota pod průměrem',
      valueAboveAverage: 'Hodnota nad průměrem',
      currentYearValue: 'Aktuální hodnota',
      helloUser: 'Dobrý den, {{value}}!',
      todayIs: 'Dnes je {{value}}',
      months: {
        JAN: 'LED',
        FEB: 'ÚNO',
        MAR: 'BŘE',
        APR: 'DUB',
        MAY: 'KVĚ',
        JUN: 'ČER',
        JUL: 'ČEV',
        AUG: 'SRP',
        SEP: 'ŘÍJ',
        OCT: 'ZÁŘ',
        NOV: 'LIS',
        DEC: 'PRO',
      },
    },
    groupsSettingsPage: {
      title: 'Skupiny',
      name: 'Název',
      comment: 'Komentář',
    },
    powerFactorSettingsPage: {
      title: 'Účiník',
      name: 'Název',
      cosPhiBreakingPoint: 'Bod zlomu cos φ',
      realPowerOdometer: 'Měřidlo činného výkonu',
      apparentPowerOdometers: 'Měřidla jalového výkonu',
    },
    enums: {
      userRoles: {
        ROLE_USER: 'Uživatel',
        ROLE_MANAGER: 'Manažer',
        ROLE_ADMIN: 'Administrátor',
      },
    },
  },
};
