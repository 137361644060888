import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {useTranslation} from 'react-i18next';
import { Box, Card, Grid, LinearProgress, Skeleton, Stack, Typography, useTheme } from "@mui/material";
import classNames from 'classnames';

import {
  ConsumptionDto, EnergyConsumptionService, InvokedAlarmDto, InvokedAlarmService
} from "@/_generatedApi";
import ConsumptionListing from '@/components/consumption-listing/ConsumptionListing';
import ElectricityConsumptionGraph from '@/components/graphs/ElectricityConsumptionGraph';
import OverviewCard from '@/components/ui/overview-card/OverviewCard';
import {UserContext} from '@/contexts/user-context/UserContext';
import {useShowToast} from '@/hooks/use-show-toast';
import Avatar from '@/layouts/dashboard-layout/Avatar';
import formatDate, {dateFormatTypes} from '@/utils/format';

import styles from './dashboard.module.css';
import { groupBy } from '@/utils/functions';
import AlarmListing from '@/components/alarms-listing/AlarmsListing';

const EnergyDashboardPage: FC = () => {
  const {t, i18n} = useTranslation();
  const theme = useTheme();
  const {showGenericErrorToast} = useShowToast();
  const {user} = useContext(UserContext);
  const [overviewData, setOverviewData] =
    useState<Map<string, ConsumptionDto[]>>();
  const [alarmsHistory, setAlarmsHistory] = useState<InvokedAlarmDto[]>(
    []
  );
  const [thisMonthConsumption, setThisMonthConsumption] = useState<ConsumptionDto[]>([])

  useEffect(() => {
    const fetchOverviewData = async () => {
      try {
        EnergyConsumptionService.getEnergyConsumptionDataHistogramByUnit().then(response => {
          if (response.data) {
            const items: Map<string, ConsumptionDto[]> = groupBy(response.data, item => item.medium.type + '-' + item.unit);
            setOverviewData(items);
          }
        })
      } catch {
        showGenericErrorToast();
      }
    };

    fetchOverviewData();
  }, [showGenericErrorToast]);

  useEffect(() => {
    EnergyConsumptionService.getEnergyConsumptionThisMonth().then(response => {
      if (response.data) {
        setThisMonthConsumption(response.data);
      }
    })
  }, [])

  const fetchAlarmsHistory = useCallback(async () => {
    try {
      const alarmsHistory = await InvokedAlarmService.getInvokedAlarmHistory({
        sortBy: 'eventDate',
        descending: true,
        // @TODO: add take to API
        //take: 5
      });

      setAlarmsHistory(alarmsHistory.data?.slice(0, 5) || []);
    } catch (e) {
      showGenericErrorToast();
    }
  }, []);

  useEffect(() => {
    fetchAlarmsHistory();
  }, []);

  return (
    <div className={classNames(styles.page)}>
      <div className={classNames(styles.welcome)}>
        <Avatar color={theme.palette.primary.main} ableMenu={false}/>
        <div className={classNames(styles.welcome, styles.content)}>
          <Typography variant="h4" component="h1">
            {t('dashboardPage.helloUser', {value: user?.firstName})}
          </Typography>
          <Typography variant="body2">
            {t('dashboardPage.todayIs', {
              value: formatDate(
                new Date(),
                dateFormatTypes.fullDate,
                i18n.language
              ),
            })}
          </Typography>
        </div>
      </div>

      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item md={3}>
            <Stack spacing={2}>
              <OverviewCard
                title={{text: t('dashboardPage.consumption'), variant: 'h5'}}
                description={t('dashboardPage.fromStartOfActualMonth')}
              >
                <ConsumptionListing
                  consumptions={thisMonthConsumption}
                />
              </OverviewCard>

              {/*<OverviewCard*/}
              {/*  title={{ text: t('dashboardPage.alarms') }}*/}
              {/*  description={t('dashboardPage.forLastSelectedPeriod')}*/}
              {/*>*/}
              {/*  <AlarmListing data={alarmsHistory} />*/}
              {/*</OverviewCard>*/}
            </Stack>
          </Grid>
          <Grid item md={9}>
            <Stack spacing={2}>
            {
              overviewData ? (
                <>
                  { Array.from(overviewData).map(([unit, value]) => (<ElectricityConsumptionGraph key={unit} data={value}/>)) }
                </>
              ) : (
                <Card
                  sx={{
                    padding: '24px',
                    color: 'primary.main',
                    flex: 1,
                  }}
                >
                  <LinearProgress/>
                  <Skeleton variant="rectangular" height={226}></Skeleton>
                </Card>
              )
            }
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default EnergyDashboardPage;
