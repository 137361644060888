import { ChangeEvent, FC, ReactNode } from 'react';
import { InputAdornment, OutlinedInput } from '@mui/material';

import { FieldProps } from '@/components/ui/form/types';

import { FieldCommonProps } from './types';
import Wrapper from './Wrapper';

const Input: FC<
  FieldCommonProps<{
    multiline?: boolean;
    startIcon?: ReactNode;
    endIcon?: ReactNode;
    autoFocus?: boolean;
  }> &
    FieldProps<string | number>
> = ({
  label,
  placeholder,
  help,
  multiline,
  onChange,
  error,
  required = false,
  value,
  type = 'text',
  startIcon,
  endIcon,
  autoFocus = false,
  ...rest
}) => {
  return (
    <Wrapper
      label={label}
      help={help}
      error={error}
      required={required}
      render={(ids) => (
        <OutlinedInput
          sx={{ backgroundColor: 'white' }}
          id={ids.current.input}
          aria-describedby={ids.current.helper}
          label={label}
          type={type}
          placeholder={placeholder}
          value={(value === 0 || value) ? value : ''}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            // const value = e.currentTarget.value;
            // if (value.trim() === '') {
            //   onChange(null);
            //   return;
            // }
            if (type === 'number') {
              onChange(parseFloat(e.currentTarget.value));
            } else {
              onChange(e.currentTarget.value);
            }
          }}
          error={!!error}
          required={required}
          startAdornment={
            startIcon && (
              <InputAdornment position="start">{startIcon}</InputAdornment>
            )
          }
          endAdornment={
            endIcon && <InputAdornment position="end">{endIcon}</InputAdornment>
          }
          {...(multiline && {
            multiline: true,
            minRows: 4,
          })}
          autoFocus={autoFocus}
          onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
          {...rest}
        />
      )}
    />
  );
};

export default Input;
